<template>
   <div class="max-w-4xl bg-white p-8 mx-auto">
    <div class="p-4">
      <div class="text-4xl text-brand">
         {{ lists.key ? 'Terms & Conditions':''}} 
      </div>
      </div>
       <div class="text-left leading-10">
          <span v-html="lists.value"></span>
       </div>
      <!-- {{ lists }} -->
   </div>
</template>

<script>
export default {

   created(){
      this.$store.dispatch('legally_binding_contract/DataListDetails');
   },
    computed:{
      lists(){
         return this.$store.state.legally_binding_contract.listDetails.terms_conditions || '';
      }
    }
}
</script>